import Image from 'next/image';

import { ConfirmDialog } from 'components/shared/confirm-dialog/confirm-dialog';
import { Modals } from 'context/modal/modals.enum';
import { useModal } from 'hooks/useModal/useModal';
import ModalIcon from 'assets/images/warning-icon.svg';
import { useTranslations } from 'hooks/useTranslations/useTranslations';
import { Button } from 'components/shared/button/button';
import { useRegisterFormSteps } from 'hooks/useRegisterFormSteps/useRegisterFormSteps';
import { useRegisterFormState } from 'hooks/useRegisterFormState/useRegisterFormState';
import { useScrollToPageSection } from 'hooks/useScrollToPageSection/useScrollToPageSection';
import { UserExistsVariants } from '../../../../context/form-state/FormState.types';

import styles from './user-exists-modal.module.scss';

export const UserExistsModal = () => {
  const { isOpen, closeModal } = useModal();
  const { translate } = useTranslations();
  const { userExistsError } = useRegisterFormState();
  const { handleStepChange } = useRegisterFormSteps();
  const { scrollToContactForm } = useScrollToPageSection();

  const onConfirm = () => {
    handleStepChange(0);
    closeModal(Modals.UserExists);
  };

  const onClose = () => {
    closeModal(Modals.UserExists);
    if (userExistsError === UserExistsVariants.OldUser) {
      scrollToContactForm();
    }
  };

  return (
    <ConfirmDialog isOpen={isOpen(Modals.UserExists)}>
      <div className={styles.icon}>
        <Image src={ModalIcon} alt="" />
      </div>

      <p className={styles.main}>{translate('register.userExistsDialog.content.main')}</p>
      <ul className={styles.list}>
        <li className={styles.content}>{translate('register.userExistsDialog.content.oldUsers')}</li>
        <li className={styles.content}>{translate('register.userExistsDialog.content.freshUsers')}</li>
      </ul>

      <div className={styles.buttonsContainer}>
        <Button onClick={onConfirm} type="button" size="big" variant="primary">
          {translate('register.userExistsDialog.buttons.checkAgain')}
        </Button>
        <Button onClick={onClose} type="button" size="big" variant="outlined">
          {userExistsError === UserExistsVariants.OldUser
            ? translate('register.userExistsDialog.buttons.contactUs')
            : translate('register.userExistsDialog.buttons.ok')}
        </Button>
      </div>
    </ConfirmDialog>
  );
};
