import clsx from 'clsx';
import React from 'react';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Button } from 'components/shared/button/button';
import { useRegisterFormSteps } from 'hooks/useRegisterFormSteps/useRegisterFormSteps';
import { useRegisterFormState } from 'hooks/useRegisterFormState/useRegisterFormState';
import { useTabs } from 'hooks/useTabs/useTabs';
import { forms } from '../forms/forms';

import styles from './form-controls.module.scss';

export const FormControls = () => {
  const { formatMessage } = useLocale();
  const { activeTab } = useTabs();
  const { activeStep, handleStepChange, steps } = useRegisterFormSteps();
  const { isLoading } = useRegisterFormState();

  const formId = forms[process.env.NEXT_PUBLIC_APP][activeTab].formId;

  return (
    <div className={styles.controls}>
      <div className={styles.buttonContainer}>
        {activeStep !== 0 && (
          <Button
            size="big"
            variant="outlined"
            className={clsx(styles.formButton, styles.buttonWithArrow, styles.arrowLeft, styles.arrowDark)}
            onClick={handleStepChange.bind(this, '-1')}
          >
            {formatMessage({
              id: AppMessages['form.prev'],
            })}
          </Button>
        )}
      </div>
      <div className={styles.stepIndicatorContainer}>
        {steps.map((_, index) => (
          <button
            onClick={handleStepChange.bind(this, index)}
            key={index}
            className={clsx(styles.dot, {
              [styles.active]: activeStep === index,
            })}
          ></button>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        {activeStep < steps.length - 1 && (
          <Button
            size="big"
            variant="primary"
            className={clsx(styles.formButton, styles.buttonWithArrow, styles.arrowRight, styles.arrowLight)}
            onClick={handleStepChange.bind(this, '+1')}
            type="button"
          >
            {formatMessage({
              id: AppMessages['form.next'],
            })}
          </Button>
        )}
        {activeStep >= steps.length - 1 && (
          <Button
            size="big"
            variant="primary"
            className={clsx(styles.formButton)}
            form={formId}
            isLoading={isLoading}
            type="submit"
          >
            {formatMessage({
              id: AppMessages['form.send'],
            })}
          </Button>
        )}
      </div>
    </div>
  );
};
