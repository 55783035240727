import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { RevenueCut } from '../icons/revenue-cut';
import { SignIcon } from '../icons/sign';
import styles from '../hbb-form.module.scss';
import { HBBInputsTypes } from '../hbb-form.types';

export const ThirdStep = () => {
  const { formatMessage } = useLocale();
  const {
    formState: { errors },
    register,
  } = useFormContext<HBBInputsTypes>();

  /*
    NOTE: we don't need logo upload anymore but it is left here in case requirements are changed. 
  */
  // const { ref: logoRef, ...logoRest } = register('logo');

  // const LogoFilePickerRef = useRef<HTMLInputElement | null>(null);

  // const LogoPickFileHandler = () => {
  //   if (LogoFilePickerRef && LogoFilePickerRef.current) {
  //     LogoFilePickerRef.current.click();
  //   }
  // };

  return (
    <>
      {/* <div className={styles.logoContainer}>
        <span className={styles.fieldLabel}>
          {formatMessage({
            id: AppMessages['register.apps.logo.label'],
          })}
        </span>
        <input
          type="file"
          {...logoRest}
          accept="image/*"
          ref={(e) => {
            logoRef(e);
            LogoFilePickerRef.current = e;
          }}
          style={{ display: 'none' }}
          multiple
        />

        <Button
          size="big"
          variant="outlined"
          className={styles.uploadButton}
          onClick={LogoPickFileHandler}
          type="button"
        >
          {formatMessage({
            id: AppMessages['form.upload'],
          })}
        </Button>
        <ul className={styles.fileList}>
          {watch('logo') !== undefined &&
            Array.from(getValues('logo') || '').map((file, index) => (
              <li className={styles.file} key={index}>
                {file.name}
              </li>
            ))}
        </ul>
        {errors.logo && <div className={styles.fieldError}>{errors.logo.message}</div>}
      </div> */}

      <div className={styles.field}>
        <div className={styles.fieldOptions}>
          <div className={clsx(styles.fieldOption, styles.checkbox)}>
            <input type="checkbox" id="agreements-preparationTime" {...register('preparationTimeAgreement')} />
            <label htmlFor="agreements-preparationTime">
              <RevenueCut />
              <span>
                {formatMessage({
                  id: AppMessages['register.apps.agreements.preparationTime'],
                })}
              </span>
            </label>
            {errors.preparationTimeAgreement && (
              <div className={clsx(styles.fieldError, styles.optionError)}>
                {errors.preparationTimeAgreement.message}
              </div>
            )}
          </div>
          <div className={clsx(styles.fieldOption, styles.checkbox)}>
            <input type="checkbox" id="agreements-sign" {...register('signAgreement')} />
            <label htmlFor="agreements-sign">
              <SignIcon />
              <span>
                {formatMessage({
                  id: AppMessages['register.apps.agreements.sign'],
                })}
              </span>
            </label>
            {errors.signAgreement && (
              <div className={clsx(styles.fieldError, styles.optionError)}>{errors.signAgreement.message}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
