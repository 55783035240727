import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { DatePicker } from 'components/shared/form/date-picker/date-picker';
import { IdField } from 'components/shared/form/id-field/id-field';
import { PhoneField } from 'components/shared/form/phone-field/phone-field';
import { Select } from 'components/shared/form/select/select';
import { Field } from 'components/shared/form/text-field/text-field';
import { Info } from 'components/shared/info/info';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { foodOptions } from '../hbb-form.utils';
import formGridStyles from '../../form-grid.module.scss';
import { usePageType } from 'hooks/usePageType/usePageType';
import { useDictionary } from 'hooks/useDictionary/useDictionary';

export const FirstStep = () => {
  const { regions, cities, getCities, isCitiesLoading, clearCitiesList } = useDictionary();
  const { isBooths } = usePageType();
  const { formatMessage, locale } = useLocale();
  const [showAdditionalInput, setShowAdditionalInput] = useState(false);

  const {
    formState: { errors },
    register,
    control,
    setValue,
  } = useFormContext();

  const selectedRegion = useWatch({ control, name: 'region' });
  const selectedFoodTypes = useWatch({ control, name: 'food' });

  useEffect(() => {
    if (selectedRegion?.key) {
      getCities(selectedRegion.key);
    }
  }, [selectedRegion, getCities, setValue]);

  useEffect(() => {
    if (cities.length > 0 && selectedRegion && cities[0]['region_id'] !== selectedRegion.id) {
      setValue('city', null);
    }

    if (cities.length > 0 && !selectedRegion) {
      setValue('city', null);
      clearCitiesList();
    }
  }, [cities, selectedRegion, setValue, clearCitiesList]);

  useEffect(() => {
    if (
      selectedFoodTypes &&
      selectedFoodTypes.filter((type: { key?: string; label: string; value: string }) => type.key === 'other')
        .length === 1
    ) {
      setShowAdditionalInput(true);
    } else {
      setShowAdditionalInput(false);
    }
  }, [selectedFoodTypes]);

  return (
    <>
      <Field
        id="fullname"
        label={formatMessage({
          id: AppMessages['register.booths.hbb.fullName.label'],
        })}
        error={errors.fullname}
        required
        register={register('fullname')}
        placeholder={formatMessage({
          id: AppMessages['register.booths.hbb.fullName.placeholder'],
        })}
      />
      <Field
        id="hbbEmail"
        label={formatMessage({
          id: AppMessages['register.booths.hbb.email.label'],
        })}
        error={errors.email}
        required={!isBooths}
        register={register('email')}
        placeholder={formatMessage({
          id: AppMessages['register.booths.hbb.email.placeholder'],
        })}
      />
      <Controller
        control={control}
        name="mobile"
        render={({ field }) => (
          <PhoneField
            field={field}
            value={field.value}
            id="mobile"
            label={formatMessage({
              id: AppMessages['register.booths.hbb.phone.label'],
            })}
            error={errors.mobile}
            required
          />
        )}
      />
      <div className={formGridStyles.row}>
        <div className={formGridStyles.col50}>
          <Controller
            control={control}
            name="nationalId"
            render={({ field }) => (
              <IdField
                field={field}
                value={field.value}
                id="nationalId"
                label={formatMessage({
                  id: AppMessages['register.booths.hbb.nationalId.label'],
                })}
                error={errors.nationalId}
                required
                placeholder="XXXXXXXXXX"
              />
            )}
          />
        </div>
        <div className={formGridStyles.col50}>
          <DatePicker
            control={control}
            monthName="idMonth"
            dayName="idDay"
            yearName="idYear"
            errors={errors}
            label={formatMessage({
              id: AppMessages['register.booths.hbb.idExpirationDate.label'],
            })}
            required
          />
        </div>
      </div>
      <Info
        variant="info"
        title={formatMessage({
          id: AppMessages['register.booths.hbb.nationalId.info.heading'],
        })}
        content={formatMessage({
          id: AppMessages['register.booths.hbb.nationalId.info.content'],
        })}
      />
      <Controller
        control={control}
        name="region"
        defaultValue={null}
        render={({ field, fieldState: { error } }) => (
          <Select
            field={field}
            options={regions.map((region) => ({
              value: region['name_ar'],
              label: region[locale === 'ar' ? 'name_ar' : 'name_en'],
              key: region['key'],
              id: region['id'],
            }))}
            id="region"
            label={formatMessage({
              id: AppMessages['register.booths.hbb.region.label'],
            })}
            error={error}
            required
          />
        )}
      />
      <div className={formGridStyles.row}>
        <div className={formGridStyles.col50}>
          <Controller
            control={control}
            name="city"
            defaultValue={null}
            render={({ field, fieldState: { error } }) => (
              <Select
                field={field}
                options={
                  cities.length > 0
                    ? cities.map((city) => ({
                        value: city['name_ar'],
                        label: city[locale === 'ar' ? 'name_ar' : 'name_en'],
                      }))
                    : []
                }
                id="city"
                label={formatMessage({
                  id: AppMessages['register.booths.hbb.city.label'],
                })}
                error={error}
                noOptionsMessage={() =>
                  formatMessage({
                    id: AppMessages['validation.city'],
                  })
                }
                isLoading={isCitiesLoading}
                required
              />
            )}
          />
        </div>
        <div className={formGridStyles.col50}>
          <Field
            id="district"
            label={formatMessage({
              id: AppMessages['register.booths.hbb.district.label'],
            })}
            error={errors?.district}
            register={register('district')}
            placeholder={formatMessage({
              id: AppMessages['register.booths.hbb.district.placeholder'],
            })}
            required
          />
        </div>
      </div>

      <Controller
        control={control}
        name="food"
        defaultValue={null}
        render={({ field, fieldState: { error } }) => (
          <Select
            field={field}
            options={foodOptions.map((option) => ({
              value: formatMessage({
                id: AppMessages[option.value],
              }),
              label: formatMessage({
                id: AppMessages[option.value],
              }),
              key: option.key || undefined,
            }))}
            id="food"
            label={formatMessage({
              id: AppMessages['register.booths.hbb.food.label'],
            })}
            error={error}
            isMulti={true}
            required
          />
        )}
      />

      {showAdditionalInput && (
        <Field
          id="otherCategories"
          label={formatMessage({
            id: AppMessages['register.booths.hbb.otherCategories.label'],
          })}
          error={errors?.otherCategories}
          register={register('otherCategories')}
          placeholder={formatMessage({
            id: AppMessages['register.booths.hbb.otherCategories.placeholder'],
          })}
        />
      )}
    </>
  );
};
