import clsx from 'clsx';
import React, { forwardRef } from 'react';

import { FieldContainer } from '../field/field';

import inputStyles from './text-field.module.scss';
import { FieldProps } from './text-field.types';

export const Field = forwardRef<HTMLInputElement, FieldProps>(
  (
    { id, label, required, error, placeholder, register, className, customContainer: Container = FieldContainer },
    innerRef,
  ) => {
    return (
      <Container id={id} label={label} required={required} errorMsg={error?.message}>
        <div className={clsx('field', className && className)}>
          <input id={id} className={inputStyles.input} placeholder={placeholder} ref={innerRef} {...register} />
        </div>
      </Container>
    );
  },
);

Field.displayName = 'Field';
