export const SignIcon = () => {
  return (
    <svg width="140" height="98" viewBox="0 0 140 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle r="48.7852" transform="matrix(-1 0 0 1 70.6288 48.7852)" fill="#DCEAFF" />
      <path
        d="M97.7542 45.7851C97.7542 45.7851 90.4342 37.4652 97.1366 32.0382C97.1366 32.0382 102.04 28.9802 106.071 29.1734C106.071 29.1734 100.485 38.821 102.343 39.7498C104.202 40.6786 107.793 28.5502 107.793 28.5502C107.793 28.5502 119.023 27.3135 122.662 29.006C122.662 29.006 113.151 36.9975 115.178 37.4384C117.204 37.8793 127.495 30.0256 127.495 30.0256C127.495 30.0256 137.982 31.6707 137.503 38.062C137.024 44.4532 133.446 46.4724 131.859 46.3231C130.272 46.1738 120.957 42.7547 120.771 44.0555C120.585 45.3563 126.22 49.5287 130.13 49.677C130.13 49.677 122.857 57.9098 117.924 55.6254C112.99 53.341 112.528 49.6207 109.45 48.2663C106.372 46.9119 103.766 46.947 105.046 48.3548C106.327 49.7627 111.876 51.0744 113.665 54.1451C115.454 57.2159 117.047 59.2796 111.964 58.5825C106.882 57.8853 98.5885 54.279 98.1706 50.0355L97.7542 45.7851Z"
        fill="#91B3FA"
      />
      <path
        d="M132.359 38.2988C132.359 38.2988 103.923 37.5715 97.7519 45.7791C97.7519 45.7791 96.417 49.9551 92.1934 53.7969L91.5516 55.6205C91.5516 55.6205 96.4099 50.3625 98.1683 50.0258C98.1665 50.0296 95.282 39.9936 132.359 38.2988Z"
        fill="#A6C2FB"
      />
      <path
        d="M92.1911 53.8023C92.1911 53.8023 86.1489 58.3939 85.8277 66.4625L86.9364 66.5468C86.9364 66.5468 88.7783 58.1849 92.2582 54.8787C95.738 51.5724 92.1911 53.8023 92.1911 53.8023Z"
        fill="#A6C2FB"
      />
      <path
        d="M42.2419 45.7851C42.2419 45.7851 49.5619 37.4652 42.8595 32.0382C42.8595 32.0382 37.9564 28.9802 33.9255 29.1734C33.9255 29.1734 39.5116 38.821 37.6527 39.7498C35.7939 40.6786 32.2031 28.5502 32.2031 28.5502C32.2031 28.5502 20.9733 27.3135 17.3338 29.006C17.3338 29.006 26.845 36.9975 24.8186 37.4384C22.7922 37.8793 12.5014 30.0256 12.5014 30.0256C12.5014 30.0256 2.01436 31.6707 2.49332 38.062C2.97227 44.4532 6.54983 46.4724 8.1368 46.3231C9.72377 46.1738 19.0396 42.7547 19.2251 44.0555C19.4107 45.3563 13.7756 49.5287 9.86567 49.677C9.86567 49.677 17.1392 57.9098 22.0725 55.6254C27.0058 53.341 27.4685 49.6207 30.5464 48.2663C33.6243 46.9119 36.2298 46.947 34.9497 48.3548C33.6696 49.7627 28.1203 51.0744 26.3313 54.1451C24.5423 57.2159 22.9488 59.2796 28.0317 58.5825C33.1146 57.8853 41.4076 54.279 41.8255 50.0355L42.2419 45.7851Z"
        fill="#91B3FA"
      />
      <path
        d="M7.63672 38.2988C7.63672 38.2988 36.0732 37.5715 42.2442 45.7791C42.2442 45.7791 43.5791 49.9551 47.8027 53.7969L48.4445 55.6205C48.4445 55.6205 43.5862 50.3625 41.8278 50.0258C41.8296 50.0296 44.7141 39.9936 7.63672 38.2988Z"
        fill="#A6C2FB"
      />
      <path
        d="M47.805 53.8023C47.805 53.8023 53.8472 58.3939 54.1684 66.4625L53.0597 66.5468C53.0597 66.5468 51.2178 58.1849 47.7379 54.8787C44.2581 51.5724 47.805 53.8023 47.805 53.8023Z"
        fill="#A6C2FB"
      />
      <path
        d="M48.7852 5.82422H92.4734V93.9289H48.7852V5.82422Z"
        fill="#7B39AC"
        stroke="#4E1777"
        strokeWidth="2.91255"
      />
      <rect x="85.1914" y="49.5137" width="2.91255" height="9.46579" fill="#D7C7F9" />
      <rect x="80.8242" y="53.8828" width="5.8251" height="1.45628" fill="white" />
      <rect
        x="57.8875"
        y="17.8387"
        width="25.4848"
        height="25.4848"
        fill="white"
        stroke="#4E1777"
        strokeWidth="0.728138"
      />
      <path
        d="M69.926 33.9407H71.336C73.6587 33.9407 75.8863 33.018 77.5287 31.3756C79.1711 29.7333 80.0938 27.5057 80.0938 25.183V24.6825C80.0938 24.3156 79.948 23.9636 79.6885 23.7041C79.429 23.4446 79.077 23.2988 78.71 23.2988H62.5483C62.1815 23.2992 61.8299 23.4451 61.5706 23.7046C61.3114 23.964 61.1658 24.3158 61.1658 24.6825V25.183C61.1658 26.3333 61.3924 27.4723 61.8327 28.535C62.2729 29.5977 62.9183 30.5633 63.7318 31.3765C64.5452 32.1898 65.511 32.8348 66.5738 33.2748C67.6366 33.7148 68.7757 33.9411 69.926 33.9407Z"
        fill="#D7C7F9"
      />
      <path
        d="M61.7 33.5332H79.5559C79.853 33.5332 80.0938 33.8604 80.0938 34.264V34.3374C80.0938 34.741 79.853 35.0682 79.5559 35.0682H61.7C61.403 35.0682 61.1622 34.741 61.1622 34.3374V34.264C61.1622 33.8604 61.403 33.5332 61.7 33.5332Z"
        fill="#D7C7F9"
      />
      <rect x="63.3477" y="37.1348" width="14.5628" height="0.728138" fill="#4E1777" />
      <rect x="64.8047" y="38.5918" width="11.6502" height="0.728138" fill="#4E1777" />
      <rect x="70.6289" y="7.28125" width="0.728138" height="9.46579" fill="white" />
      <rect x="70.6289" y="44.416" width="0.728138" height="9.46579" fill="white" />
      <rect
        x="91.582"
        y="9.48047"
        width="0.728138"
        height="9.46579"
        transform="rotate(45 91.582 9.48047)"
        fill="white"
      />
      <rect
        x="56.5859"
        y="44.4766"
        width="0.728138"
        height="9.46579"
        transform="rotate(45 56.5859 44.4766)"
        fill="white"
      />
      <rect
        x="92.0938"
        y="51.1699"
        width="0.728138"
        height="9.46579"
        transform="rotate(135 92.0938 51.1699)"
        fill="white"
      />
      <rect
        x="57.0977"
        y="16.1719"
        width="0.728138"
        height="9.46579"
        transform="rotate(135 57.0977 16.1719)"
        fill="white"
      />
      <rect
        x="94.293"
        y="30.2168"
        width="0.728138"
        height="9.46579"
        transform="rotate(90 94.293 30.2168)"
        fill="white"
      />
      <rect
        x="57.1602"
        y="30.2168"
        width="0.728138"
        height="9.46579"
        transform="rotate(90 57.1602 30.2168)"
        fill="white"
      />
    </svg>
  );
};
