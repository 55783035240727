import { formType } from './forms.types';

export const forms: formType = {
  booths: [
    {
      formId: 'booths-hbb',
      steps: ['register.booths.hbb.step1', 'register.booths.hbb.step2'],
    },
    {
      formId: 'booths-partners',
      steps: ['register.booths.partners.step1', 'register.booths.partners.step2'],
    },
  ],
  apps: [
    {
      formId: 'apps-hbb',
      steps: ['register.apps.step1', 'register.apps.step2', 'register.apps.step3'],
    },
  ],
};
