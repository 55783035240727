import clsx from 'clsx';
import React from 'react';
import NumberFormat from 'react-number-format';

import { FieldContainer } from '../field/field';

import inputStyles from './phone-field.module.scss';
import { PhoneFieldProps } from './phone-field.types';

export const PhoneField = <InputTypes,>({
  id,
  label,
  required,
  error,
  placeholder,
  field,
  value,
  className,
  customContainer: Container = FieldContainer,
}: PhoneFieldProps<InputTypes>) => {
  return (
    <Container id={id} label={label} required={required} errorMsg={error?.message}>
      <div className={clsx('field', inputStyles.containerDir, className && className)}>
        <NumberFormat
          {...field}
          id={id}
          type="tel"
          value={value}
          className={inputStyles.input}
          placeholder={placeholder}
          format="+966 ### ### ###"
          mask="_"
          allowEmptyFormatting={true}
          displayType="input"
        />
      </div>
    </Container>
  );
};
