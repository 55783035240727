export const MAX_BOOTH_NUMBER = 10;

export const numberOfEmployeesOptions = [
  { value: 'upTo50', label: '0-50' },
  { value: 'upTo100', label: '50-100' },
  { value: 'upTo200', label: '100-200' },
  { value: 'upTo500', label: '200-500' },
  { value: 'over500', label: '500+' },
];

export const numberOfBoothsOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];
