import * as yup from 'yup';

export const generateSchema = (translate: any, isBooths: boolean) => {
  const emailValidation = isBooths
    ? yup.string().email(translate('validation.email'))
    : yup.string().email(translate('validation.email')).required(translate('validation.required'));

  const firstStepFields = {
    email: emailValidation,
    fullname: yup.string().required(translate('validation.required')),
    mobile: yup
      .string()
      .matches(
        /\+966\s([0-9]{3})\s([0-9]{3})\s([0-9]{3})/,

        translate('validation.phone'),
      )
      .required(translate('validation.required')),
    nationalId: yup
      .string()
      .matches(
        /([0-9]{10})/,

        translate('validation.nationalId'),
      )
      .required(translate('validation.required')),
    idDay: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(translate('validation.required'))
      .min(1, translate('validation.date'))
      .max(30, translate('validation.date')),
    idMonth: yup.object().required(translate('validation.required')),
    idYear: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1400, translate('validation.date'))
      .required(translate('validation.required')),
    region: yup.object().nullable().required(translate('validation.required')),
    city: yup.object().nullable().required(translate('validation.required')),
    district: yup.string().required(translate('validation.required')),
    food: yup.array().nullable().required(translate('validation.required')).min(1, translate('validation.required')),
    otherCategories: yup.string(),
  };

  const secondStepFields = {
    hc: yup.number().nullable().required(translate('validation.required')),

    hcDay: yup.mixed().when('hc', {
      is: (value: 0 | 1 | null) => value && value === 1,
      then: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(translate('validation.required'))
        .min(1, translate('validation.date'))
        .max(30, translate('validation.date')),
    }),

    hcMonth: yup.object().when('hc', {
      is: (value: 0 | 1) => value && value === 1,
      then: yup.object().required(translate('validation.required')),
    }),

    hcYear: yup.mixed().when('hc', {
      is: (value: 0 | 1) => value && value === 1,
      then: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(translate('validation.required')),
    }),

    healthCertificateNumber: yup.string().when('hc', {
      is: (value: 0 | 1) => value && value === 1,
      then: yup.string().required(translate('validation.required')),
    }),
    hcFile: yup.mixed().when('hc', {
      is: (value: 0 | 1) => value && value === 1,
      then: yup.mixed().test('hcFile', translate('validation.required'), (value: FileList | undefined) => {
        if (!value) return false;

        return value.length > 0;
      }),
    }),
  };

  const thirdStepFields = {
    // TODO: uncomment if logo is needed
    // logo: yup.mixed().test(
    //   'logo',
    //   formatMessage({
    //     id: AppMessages['validation.required'],
    //   }),
    //   (value: FileList | undefined) => {
    //     if (!value) return false;

    //     return value.length > 0;
    //   },
    // ),
    preparationTimeAgreement: yup
      .boolean()
      .test('preparationTimeAgreement', translate('validation.required'), (value: boolean | undefined) => !!value),
    signAgreement: yup
      .boolean()
      .test('signAgreement', translate('validation.required'), (value: boolean | undefined) => !!value),
  };

  if (process.env.NEXT_PUBLIC_APP === 'apps') {
    return yup.object().shape({
      ...firstStepFields,
      ...secondStepFields,
      ...thirdStepFields,
    });
  } else {
    return yup.object().shape({
      ...firstStepFields,
      ...secondStepFields,
    });
  }
};
