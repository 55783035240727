import { useContext } from 'react';

import { DictionaryContextType } from 'context/dictionary/DictionaryContext.types';
import { DictionaryContext } from 'context/dictionary/DictionaryContextController';

export const useDictionary: () => DictionaryContextType = () => {
  const context = useContext(DictionaryContext);

  if (context === undefined) {
    throw new Error('useDictionary must be used within an DictionaryController');
  }

  return context;
};
