import clsx from 'clsx';
import React from 'react';

import styles from './field.module.scss';
import { FieldProps } from './field.types';

export const FieldContainer = ({ id, label, required, errorMsg, children }: FieldProps) => {
  return (
    <div
      className={clsx(styles.container, {
        [styles.withError]: !!errorMsg,
      })}
    >
      {label && (
        <label
          className={clsx(styles.label, {
            [styles.required]: required,
          })}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {children}
      {errorMsg && <div className={styles.error}>{errorMsg}</div>}
    </div>
  );
};
