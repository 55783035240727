import clsx from 'clsx';

import styles from './button.module.scss';
import { ButtonProps } from './button.types';

export const Button = ({
  children,
  className,
  type,
  variant,
  size,
  onClick,
  form,
  isLoading,
  disabled,
}: ButtonProps) => {
  return (
    <button
      form={form}
      type={type}
      onClick={onClick}
      className={clsx(className, variant && styles[variant], size && styles[size])}
      disabled={disabled || isLoading}
    >
      {!isLoading ? children : <span className={styles.loading} />}
    </button>
  );
};
