import { AppMessages } from 'i18n/messages';

export const generateMonthList = (formatMessage: any) => [
  {
    value: '1',
    label: formatMessage({
      id: AppMessages['months.1st'],
    }),
  },
  {
    value: '2',
    label: formatMessage({
      id: AppMessages['months.2nd'],
    }),
  },
  {
    value: '3',
    label: formatMessage({
      id: AppMessages['months.3rd'],
    }),
  },
  {
    value: '4',
    label: formatMessage({
      id: AppMessages['months.4th'],
    }),
  },
  {
    value: '5',
    label: formatMessage({
      id: AppMessages['months.5th'],
    }),
  },
  {
    value: '6',
    label: formatMessage({
      id: AppMessages['months.6th'],
    }),
  },
  {
    value: '7',
    label: formatMessage({
      id: AppMessages['months.7th'],
    }),
  },
  {
    value: '8',
    label: formatMessage({
      id: AppMessages['months.8th'],
    }),
  },
  {
    value: '9',
    label: formatMessage({
      id: AppMessages['months.9th'],
    }),
  },
  {
    value: '10',
    label: formatMessage({
      id: AppMessages['months.10th'],
    }),
  },
  {
    value: '11',
    label: formatMessage({
      id: AppMessages['months.11th'],
    }),
  },
  {
    value: '12',
    label: formatMessage({
      id: AppMessages['months.12th'],
    }),
  },
];
