import { FormStateContextController } from 'context/form-state/FormStateContextController';
import { FormStepsContextController } from 'context/form-steps/FormStepsContextController';

import { Modals } from './modals/modals.container';
import { Register } from './register';

export const RegisterContainer = () => {
  return (
    <FormStateContextController>
      <FormStepsContextController>
        <Register />
        <Modals />
      </FormStepsContextController>
    </FormStateContextController>
  );
};
