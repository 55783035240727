import { AppMessages } from 'i18n/messages';

export const foodOptions: { value: keyof typeof AppMessages; key?: string }[] = [
  { value: 'foodCategories.no1' },
  { value: 'foodCategories.no2' },
  { value: 'foodCategories.no3' },
  { value: 'foodCategories.no4' },
  { value: 'foodCategories.no5' },
  { value: 'foodCategories.no6' },
  { value: 'foodCategories.no7' },
  { value: 'foodCategories.no8' },
  { value: 'foodCategories.no9' },
  { value: 'foodCategories.no10' },
  { value: 'foodCategories.no11' },
  { value: 'foodCategories.no12', key: 'other' },
];
