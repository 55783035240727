import clsx from 'clsx';
import ReactSelect, { GroupBase } from 'react-select';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { FieldContainer } from '../field/field';

import selectStyles from './select.module.scss';
import { SelectProps } from './select.types';

export const Select = <InputsTypes, Option extends { value: string; label: string }, Group extends GroupBase<Option>>({
  id,
  label,
  required,
  error,
  options,
  field,
  placeholder,
  isMulti,
  noOptionsMessage,
  isLoading,
}: SelectProps<InputsTypes, Option, Group>) => {
  const { formatMessage } = useLocale();

  return (
    <FieldContainer id={`react-select-${id}-input`} label={label} required={required} errorMsg={error?.message}>
      <ReactSelect<{ value: string; label: string }, boolean>
        {...field}
        instanceId={id}
        options={options}
        className={clsx(selectStyles.select, {
          [selectStyles.withError]: !!error,
        })}
        classNamePrefix="select-field"
        isClearable
        isMulti={isMulti}
        placeholder={
          placeholder ||
          formatMessage({
            id: AppMessages['form.select'],
          })
        }
        noOptionsMessage={noOptionsMessage}
        isLoading={isLoading}
      />
    </FieldContainer>
  );
};
