import React from 'react';
import Image from 'next/image';

import { ConfirmDialog } from 'components/shared/confirm-dialog/confirm-dialog';
import { Button } from 'components/shared/button/button';
import ModalIcon from 'assets/images/warning-icon.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useModal } from 'hooks/useModal/useModal';
import { Modals } from 'context/modal/modals.enum';
import { useTabs } from 'hooks/useTabs/useTabs';
import { useScrollToPageSection } from 'hooks/useScrollToPageSection/useScrollToPageSection';
import { useRegisterFormSteps } from 'hooks/useRegisterFormSteps/useRegisterFormSteps';

import styles from './confirm.module.scss';

export const Confirm = () => {
  const { formatMessage } = useLocale();
  const { isOpen, closeModal } = useModal();
  const { targetTab, setActiveTab } = useTabs();
  const { scrollToRegister } = useScrollToPageSection();
  const { handleStepChange } = useRegisterFormSteps();

  const onConfirmedTabSwitch = () => {
    setActiveTab(targetTab);
    closeModal(Modals.SwitchForm);
    handleStepChange(0);
    scrollToRegister();
  };

  return (
    <ConfirmDialog isOpen={isOpen(Modals.SwitchForm)}>
      <div className={styles.icon}>
        <Image src={ModalIcon} alt="" />
      </div>
      <p className={styles.content}>
        {formatMessage({
          id: AppMessages['register.confirmDialog.content.question'],
        })}
      </p>
      <p className={styles.content}>
        {formatMessage({
          id: AppMessages['register.confirmDialog.content.warning'],
        })}
      </p>
      <div className={styles.buttonsContainer}>
        <Button onClick={onConfirmedTabSwitch} type="button" size="big" variant="outlined">
          {formatMessage({
            id: AppMessages['register.confirmDialog.buttons.switch'],
          })}
        </Button>
        <Button
          onClick={() => {
            closeModal(Modals.SwitchForm);
          }}
          type="button"
          size="big"
          variant="primary"
        >
          {formatMessage({
            id: AppMessages['register.confirmDialog.buttons.cancel'],
          })}
        </Button>
      </div>
    </ConfirmDialog>
  );
};
