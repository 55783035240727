import { SubmitErrorHandler, SubmitHandler, useForm, useFormState, FormProvider } from 'react-hook-form';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import moment from 'moment-hijri';

import { AppMessages } from 'i18n/messages';
import { useAuth } from 'hooks/useAuth/useAuth';
import { usePageType } from 'hooks/usePageType/usePageType';
import { useModal } from 'hooks/useModal/useModal';
import { Modals } from 'context/modal/modals.enum';
import { useRegisterFormSteps } from 'hooks/useRegisterFormSteps/useRegisterFormSteps';
import { useRegisterFormState } from 'hooks/useRegisterFormState/useRegisterFormState';
import { useTabs } from 'hooks/useTabs/useTabs';
import { forms } from '../forms';
import { useTranslations } from 'hooks/useTranslations/useTranslations';
import { UserExistsVariants } from 'context/form-state/FormState.types';

import { HBBInputsTypes, PreparedDataType } from './hbb-form.types';
import { generateSchema } from './hbb-form.validation';
import { FirstStep } from './steps/firstStep';
import { SecondStep } from './steps/secondStep';
import { ThirdStep } from './steps/thirdStep';

export const HBBForm = () => {
  const { isApps, isBooths } = usePageType();
  const router = useRouter();
  const { token } = useAuth();
  const { showModal } = useModal();
  const { activeTab } = useTabs();
  const { activeStep, handleStepChange } = useRegisterFormSteps();
  const { setLoadingState, setIsDirty, setUserExistsError } = useRegisterFormState();
  const { translate } = useTranslations();

  const formId = forms[process.env.NEXT_PUBLIC_APP][activeTab].formId;

  const { control, handleSubmit, ...formMethods } = useForm<HBBInputsTypes>({
    resolver: yupResolver(generateSchema(translate, isBooths)),
    mode: 'onSubmit',
    defaultValues: {
      idDay: '',
      idYear: '',
      hcDay: '',
      hcYear: '',
    },
  });

  const { isDirty } = useFormState({ control });

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  const onSubmit: SubmitHandler<HBBInputsTypes> = (data) => {
    setLoadingState(true);
    const parsedDate = moment(`${data.idYear}/${data.idMonth.value}/${data.idDay}`, 'iYYYY/iM/iD').toISOString();

    if (!parsedDate) {
      toast.error(translate('validation.date'));
      setLoadingState(false);
      return new Error(translate('validation.date'));
    }

    const formData = new FormData();
    const preparedData: PreparedDataType = {
      fullname: data.fullname,
      email: data.email,
      mobile: data.mobile,
      nationalId: data.nationalId,
      nationalIdExpirationDate: parsedDate,
      region: data?.region?.value,
      city: data?.city?.value,
      district: data.district,
      foodTypes: (data.food || []).map((el) => el.value),
      foodOtherCategories: data.otherCategories,
    };

    if (data.hc === 1) {
      Array.from(data.hcFile).forEach((file) => {
        formData.append('files.healthCertificateFile', file, file.name);
      });
      const parsedDate = moment(`${data.hcYear}/${data.hcMonth.value}/${data.hcDay}`, 'iYYYY/iM/iD').toISOString();

      if (!parsedDate) {
        toast.error(translate('validation.date'));
        setLoadingState(false);
        return new Error(translate('validation.date'));
      }

      preparedData.healthCertificateNumber = data.healthCertificateNumber;
      preparedData.healthCertificateExpirationDate = parsedDate;
    }

    if (isApps) {
      preparedData.isOrderReadyWithinTwentyMinutes = data.preparationTimeAgreement;
      preparedData.agreeToPlaceSign = data.signAgreement;

      // NOTE: uncomment if logo upload is needed
      // Array.from(data.logo).forEach((file) => {
      //   formData.append('files.logo', file, file.name);
      // });
    }

    formData.append('data', JSON.stringify(preparedData));

    axios
      .post(`${process.env.NEXT_PUBLIC_API}/api/${isApps ? 'apps' : 'home-based-businesses'}`, formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        setLoadingState(false);
        router.push({ pathname: '/success', query: { email: data.email } }, '/success');
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        const { status, fields } = error.response.data;

        setLoadingState(false);
        if (status === 409) {
          const errorMessage = error.response.data.error as keyof typeof AppMessages;
          const variant = error.response.data.isMoreThanTenDays
            ? UserExistsVariants.OldUser
            : UserExistsVariants.FreshUser;

          showModal(Modals.UserExists);
          setUserExistsError(variant);

          fields.forEach((error: keyof HBBInputsTypes) => {
            formMethods.setError(error, {
              message: translate(errorMessage),
            });
          });
        } else {
          toast.error(translate('messages.error'));
        }
      });
  };

  const onError: SubmitErrorHandler<HBBInputsTypes> = (errors) => {
    const firstStepFields = [
      'fullname',
      'email',
      'mobile',
      'nationalId',
      'idDay',
      'idMonth',
      'idYear',
      'region',
      'city',
      'district',
      'food',
    ];
    const secondStepFields = ['hc', 'hcDay', 'hcMonth', 'hcYear', 'healthCertificateNumber', 'hcFile'];
    const fieldsWithErrors = Object.keys(errors);

    if (fieldsWithErrors.find((field) => firstStepFields.includes(field))) {
      handleStepChange(0);
    } else if (fieldsWithErrors.find((field) => secondStepFields.includes(field))) {
      handleStepChange(1);
    }
  };

  return (
    <FormProvider control={control} handleSubmit={handleSubmit} {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onError)} id={formId}>
        {activeStep === 0 && <FirstStep />}
        {activeStep === 1 && <SecondStep />}
        {activeStep === 2 && <ThirdStep />}
      </form>
    </FormProvider>
  );
};
