import { useContext } from 'react';

import { UIContextType } from 'context/ui/UIContext.types';
import { UIContext } from 'context/ui/UIContextController';

export const useTabs: () => UIContextType = () => {
  const context = useContext(UIContext);

  if (context === undefined) {
    throw new Error('useTabs must be used within an UIContextController');
  }

  return context;
};
