import { createContext, useState } from 'react';

import { forms } from 'components/register/forms/forms';
import { useScrollToPageSection } from 'hooks/useScrollToPageSection/useScrollToPageSection';
import { useTabs } from 'hooks/useTabs/useTabs';

import { FormStepsContextType, FormStepsControllerProps } from './FormSteps.types';

export const FormStepsContext = createContext<FormStepsContextType | undefined>(undefined);

export const FormStepsContextController = ({ children }: FormStepsControllerProps) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const { scrollToRegister } = useScrollToPageSection();
  const { activeTab } = useTabs();

  const steps = forms[process.env.NEXT_PUBLIC_APP][activeTab].steps;

  const handleStepChange = (step?: number | '-1' | '+1') => {
    const lastStep = steps.length - 1;

    if (step === '+1' && activeStep < lastStep) {
      setActiveStep((prev) => prev + 1);
    }

    if (step === '-1' && activeStep !== 0) {
      setActiveStep((prev) => prev - 1);
    }

    if (typeof step === 'number' && step <= lastStep && step >= 0 && step !== activeStep) {
      setActiveStep(step);
    }

    scrollToRegister();
  };

  return (
    <FormStepsContext.Provider value={{ activeStep, handleStepChange, steps }}>{children}</FormStepsContext.Provider>
  );
};
