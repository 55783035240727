import { ReactNode } from 'react';

export enum UserExistsVariants {
  FreshUser = 'fresh-user',
  OldUser = 'old-user',
}

export type FormStateControllerProps = {
  children: ReactNode;
};

export type FormStateContextType = {
  isLoading: boolean;
  setLoadingState: (value: boolean) => void;
  isDirty: boolean;
  setIsDirty: (value: boolean) => void;
  userExistsError: 'fresh-user' | 'old-user' | null;
  setUserExistsError: (type: UserExistsVariants.FreshUser | UserExistsVariants.OldUser | null) => void;
};
