import { Confirm } from './confirm/confirm';
import { UserExistsModal } from './user-exists/user-exists-modal';

export const Modals = () => {
  return (
    <>
      <Confirm />
      <UserExistsModal />
    </>
  );
};
