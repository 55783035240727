import { useContext } from 'react';

import { AuthContextType } from 'context/auth/AuthContext.types';
import { AuthContext } from 'context/auth/AuthContextController';

export const useAuth: () => AuthContextType = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthState must be used within an AuthContextController');
  }

  return context;
};
