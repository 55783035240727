import { useContext } from 'react';

import { FormStateContextType } from 'context/form-state/FormState.types';
import { FormStateContext } from 'context/form-state/FormStateContextController';

export const useRegisterFormState: () => FormStateContextType = () => {
  const context = useContext(FormStateContext);

  if (context === undefined) {
    throw new Error('useRegisterFormState must be used within an FormStateContextController');
  }

  return context;
};
