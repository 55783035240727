import clsx from 'clsx';
import { useState } from 'react';

import { InfoPropsTypes } from './info.types';
import styles from './info.module.scss';

export const Info = ({ title, content, variant }: InfoPropsTypes) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div onClick={() => setIsCollapsed((prev) => !prev)} className={clsx(styles.root)}>
      <div
        className={clsx(styles.title, variant && styles[variant], {
          [styles.clicable]: !!content,
        })}
      >
        {title}
      </div>
      {content && !isCollapsed && <div className={styles.content}>{content}</div>}
    </div>
  );
};
