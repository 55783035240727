import { useContext } from 'react';

import { FormStepsContextType } from 'context/form-steps/FormSteps.types';
import { FormStepsContext } from 'context/form-steps/FormStepsContextController';

export const useRegisterFormSteps: () => FormStepsContextType = () => {
  const context = useContext(FormStepsContext);

  if (context === undefined) {
    throw new Error('useRegisterFormSteps must be used within an FormStepsContextController');
  }

  return context;
};
