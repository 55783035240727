import clsx from 'clsx';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useTabs } from 'hooks/useTabs/useTabs';

import styles from './tabs.module.scss';
import { TabsProps } from './tabs.types';

const BORDER_WIDTH = '2px';

export const Tabs = ({ onChange, tabs, className }: TabsProps) => {
  const { locale } = useLocale();
  const { activeTab } = useTabs();

  return (
    <div className={clsx(styles.tabs, className && className)}>
      {tabs.map((tab, i) => (
        <button
          key={i}
          style={{ width: `calc(100% / ${tabs.length})` }}
          className={clsx(styles.tab, {
            [styles.activeTab]: i === activeTab,
          })}
          onClick={() => onChange(i)}
        >
          {tab}
        </button>
      ))}
      <div
        style={{
          [locale === 'en' ? 'left' : 'right']: `calc(${activeTab} * 100% / ${tabs.length} - ${BORDER_WIDTH})`,
          width: `calc(100% / ${tabs.length} + ${BORDER_WIDTH} * 2)`,
        }}
        className={styles.focused}
      />
    </div>
  );
};
