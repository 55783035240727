import { createContext, useCallback, useState } from 'react';

import { FormStateContextType, FormStateControllerProps } from './FormState.types';

export const FormStateContext = createContext<FormStateContextType | undefined>(undefined);

export const FormStateContextController = ({ children }: FormStateControllerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [userExistsError, setUserExistsError] = useState<'fresh-user' | 'old-user' | null>(null);

  const setLoadingState = (value: boolean) => {
    setIsLoading(value);
  };

  const setIsDirty = useCallback((isDirty) => {
    setIsFormDirty(isDirty);
  }, []);

  return (
    <FormStateContext.Provider
      value={{ isLoading, setLoadingState, isDirty: isFormDirty, setIsDirty, userExistsError, setUserExistsError }}
    >
      {children}
    </FormStateContext.Provider>
  );
};
