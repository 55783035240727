import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './section-header.module.scss';

export type SectionHeaderProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  className?: string;
  id?: string;
};

export const SectionHeader = ({ title, subtitle, className, id }: SectionHeaderProps) => {
  return (
    <div id={id} className={clsx(styles.root, className)}>
      <h2 className={styles.heading}>{title}</h2>
      {subtitle && <h3 className={styles.subHeading}>{subtitle}</h3>}
    </div>
  );
};
