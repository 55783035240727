import clsx from 'clsx';
import { useRouter } from 'next/router';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useRegisterFormSteps } from 'hooks/useRegisterFormSteps/useRegisterFormSteps';
import { AppMessages } from 'i18n/messages';

import styles from './form-steps.module.scss';
import { FormStepsProps } from './form-steps.types';

export const FormSteps = ({ className }: FormStepsProps) => {
  const { formatMessage } = useLocale();
  const { steps, activeStep } = useRegisterFormSteps();
  const { route } = useRouter();
  const isMainPage = route === '/';

  return (
    <ul
      className={clsx(styles.steps, {
        [styles.isStatic]: !isMainPage,
      })}
    >
      {steps.map((step, index) => (
        <li key={index} className={clsx(styles.step, className && className)}>
          <div
            className={clsx(styles.stepNumber, {
              [styles.activeStep]: index === activeStep,
            })}
          >
            <span>{index + 1}</span>
          </div>
          {formatMessage({
            id: AppMessages[step],
          })}
        </li>
      ))}
    </ul>
  );
};
