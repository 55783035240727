import React from 'react';
import clsx from 'clsx';

import { SectionHeader } from 'components/shared/section-header/section-header';
import { Tabs } from 'components/shared/tabs/tabs';
import { useTabs } from 'hooks/useTabs/useTabs';
import { usePageType } from 'hooks/usePageType/usePageType';
import { HBBForm as TojjarHBBForm } from 'components/register/forms/hbb/hbb-form';
import { PartnersForm as TojjarBoothsPartnerForm } from 'components/register/forms/partner/partners-form';
import { useScrollToPageSection } from 'hooks/useScrollToPageSection/useScrollToPageSection';
import { useRegisterFormSteps } from 'hooks/useRegisterFormSteps/useRegisterFormSteps';
import { useRegisterFormState } from 'hooks/useRegisterFormState/useRegisterFormState';
import { useModal } from 'hooks/useModal/useModal';
import { Modals } from 'context/modal/modals.enum';
import { useTranslations } from 'hooks/useTranslations/useTranslations';

import styles from './register.module.scss';
import { FormSteps } from './form-steps/form-steps';
import { FormControls } from './form-controls/form-controls';

export const Register = () => {
  const { isBooths, isApps } = usePageType();
  const { scrollToRegister } = useScrollToPageSection();
  const { activeStep, handleStepChange, steps } = useRegisterFormSteps();
  const { isDirty } = useRegisterFormState();
  const { activeTab, setActiveTab, setTargetTab } = useTabs();
  const { showModal } = useModal();
  const { translate } = useTranslations();

  const handleTabChange = (tab: number) => {
    if (isDirty) {
      setTargetTab(tab);
      showModal(Modals.SwitchForm);
      return;
    }

    setActiveTab(tab);
    handleStepChange(0);
    scrollToRegister();
  };

  return (
    <>
      <SectionHeader id="register_as" title={translate('register.heading')} className={styles.sectionHeader} />
      {isBooths && <Tabs onChange={handleTabChange} tabs={[translate('tabs.hbb'), translate('tabs.partners')]} />}

      <div className={styles.container}>
        <FormSteps className={clsx(isApps && styles.appsStep)} />
        <div>
          <h2 className={styles.heading}>{translate(steps[activeStep])}</h2>
        </div>
        <div className={styles.requiredInfo}>{translate('form.fieldRequired')}</div>

        {(isApps || (isBooths && activeTab === 0)) && <TojjarHBBForm />}

        {isBooths && activeTab === 1 && <TojjarBoothsPartnerForm />}

        <FormControls />
      </div>
    </>
  );
};
