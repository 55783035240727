import { Controller, SubmitErrorHandler, SubmitHandler, useForm, useFormState, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

import formGridStyles from '../form-grid.module.scss';
import { Field } from 'components/shared/form/text-field/text-field';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { PhoneField } from 'components/shared/form/phone-field/phone-field';
import { Select } from 'components/shared/form/select/select';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useDictionary } from 'hooks/useDictionary/useDictionary';
import { useRegisterFormSteps } from 'hooks/useRegisterFormSteps/useRegisterFormSteps';
import { useTabs } from 'hooks/useTabs/useTabs';
import { forms } from '../forms';
import { useRegisterFormState } from 'hooks/useRegisterFormState/useRegisterFormState';
import { Modals } from '../../../../context/modal/modals.enum';
import { UserExistsVariants } from '../../../../context/form-state/FormState.types';
import { useModal } from '../../../../hooks/useModal/useModal';

import { generateSchema } from './partners-form.validation';
import { numberOfBoothsOptions, numberOfEmployeesOptions } from './partners-form.utils';
import { PartnersInputsTypes } from './partners-form.types';
import styles from './partners-form.module.scss';

export const PartnersForm = () => {
  const router = useRouter();
  const { formatMessage, locale } = useLocale();
  const { token } = useAuth();
  const { showModal } = useModal();
  const { activeTab } = useTabs();
  const { regions, cities, getCities, isCitiesLoading } = useDictionary();
  const { activeStep, handleStepChange } = useRegisterFormSteps();
  const { setIsDirty, setLoadingState, setUserExistsError } = useRegisterFormState();

  const formId = forms[process.env.NEXT_PUBLIC_APP][activeTab].formId;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    ...formMethods
  } = useForm<PartnersInputsTypes>({ resolver: yupResolver(generateSchema(formatMessage)), mode: 'onSubmit' });

  const { isDirty } = useFormState({
    control,
  });

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  const selectedRegion = useWatch({ control, name: 'booths.region' });

  useEffect(() => {
    if (selectedRegion?.key) {
      getCities(selectedRegion.key);
    }
  }, [selectedRegion, getCities, setValue]);

  useEffect(() => {
    if (cities.length > 0 && selectedRegion && cities[0]['region_id'] !== selectedRegion.id) {
      setValue('booths.city', null);
    }
  }, [cities, selectedRegion, setValue]);

  const onSubmit: SubmitHandler<PartnersInputsTypes> = (data) => {
    setLoadingState(true);
    const preparedData = {
      ...data,
      boothsCount: data.booths.boothsCount !== null ? Number(data.booths.boothsCount.value) : null,
      booths: [
        {
          ...data.booths,
          region: data.booths.region?.value,
          city: data.booths.city?.value,
          boothsCount: undefined,
          employees: data.booths.employees !== null ? data.booths.employees.value : null,
        },
      ],
    };

    axios
      .post(`${process.env.NEXT_PUBLIC_API}/api/booth-companies`, preparedData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        setLoadingState(false);
        router.push({ pathname: '/success', query: { email: data.email } }, '/success');
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        const { status, fields } = error.response.data;
        setLoadingState(false);

        if (status === 409) {
          const errorMessage = error.response.data.error as keyof typeof AppMessages;
          showModal(Modals.UserExists);

          const variant = error.response.data.isMoreThanTenDays
            ? UserExistsVariants.OldUser
            : UserExistsVariants.FreshUser;
          setUserExistsError(variant);

          fields.forEach((error: keyof PartnersInputsTypes) => {
            formMethods.setError(error, {
              message: formatMessage({ id: errorMessage }),
            });
          });
        } else {
          toast.error(
            formatMessage({
              id: AppMessages['messages.error'],
            }),
          );
        }
      });
  };

  const onError: SubmitErrorHandler<PartnersInputsTypes> = (errors) => {
    const firstStepFields = ['businessName', 'fullname', 'email', 'mobileNumber'];
    const fieldsWithErrors = Object.keys(errors);

    if (fieldsWithErrors.find((field) => firstStepFields.includes(field))) {
      handleStepChange(0);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} id={formId}>
      {activeStep === 0 ? (
        <>
          <Field
            id="businessName"
            label={formatMessage({
              id: AppMessages['register.booths.partners.businessName.label'],
            })}
            error={errors.businessName}
            required
            register={register('businessName')}
            placeholder={formatMessage({
              id: AppMessages['register.booths.partners.businessName.placeholder'],
            })}
          />
          <Field
            id="fullname"
            label={formatMessage({
              id: AppMessages['register.booths.partners.fullName.label'],
            })}
            error={errors.fullname}
            required
            register={register('fullname')}
            placeholder={formatMessage({
              id: AppMessages['register.booths.partners.fullName.placeholder'],
            })}
          />
          <div className={formGridStyles.row}>
            <div className={formGridStyles.col50}>
              <Field
                id="partnerEmail"
                label={formatMessage({
                  id: AppMessages['register.booths.partners.email.label'],
                })}
                error={errors.email}
                required
                register={register('email')}
                placeholder={formatMessage({
                  id: AppMessages['register.booths.partners.email.placeholder'],
                })}
              />
            </div>
            <div className={formGridStyles.col50}>
              <Controller
                control={control}
                name="mobileNumber"
                render={({ field }) => (
                  <PhoneField
                    field={field}
                    value={field.value}
                    id="mobileNumber"
                    label={formatMessage({
                      id: AppMessages['register.booths.partners.phone.label'],
                    })}
                    error={errors.mobileNumber}
                    required
                  />
                )}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={styles.fieldset}>
          <div className={styles.boothHeading}>
            <h3>
              {formatMessage(
                {
                  id: AppMessages['register.booths.partners.booth.label'],
                },
                { boothNumber: 1 },
              )}
            </h3>
          </div>
          <div className={formGridStyles.row}>
            <div className={formGridStyles.col50}>
              <Controller
                control={control}
                name="booths.region"
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    field={field}
                    options={regions.map((region) => ({
                      value: region['name_ar'],
                      label: region[locale === 'ar' ? 'name_ar' : 'name_en'],
                      key: region['key'],
                      id: region['id'],
                    }))}
                    id="booths.region"
                    label={formatMessage({
                      id: AppMessages['register.booths.hbb.region.label'],
                    })}
                    error={error}
                    required
                  />
                )}
              />
            </div>
            <div className={formGridStyles.col50}>
              <Controller
                control={control}
                name="booths.city"
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    field={field}
                    options={
                      cities.length > 0
                        ? cities.map((city) => ({
                            value: city['name_ar'],
                            label: city[locale === 'ar' ? 'name_ar' : 'name_en'],
                          }))
                        : []
                    }
                    id="booths.city"
                    label={formatMessage({
                      id: AppMessages['register.booths.hbb.city.label'],
                    })}
                    error={error}
                    noOptionsMessage={() =>
                      formatMessage({
                        id: AppMessages['validation.city'],
                      })
                    }
                    isLoading={isCitiesLoading}
                    required
                  />
                )}
              />
            </div>
          </div>
          <div className={formGridStyles.row}>
            <div className={formGridStyles.col50}>
              <Field
                id="address"
                label={formatMessage({
                  id: AppMessages['register.booths.partners.address.label'],
                })}
                error={errors?.booths?.address}
                register={register(`booths.address`)}
                placeholder={formatMessage({
                  id: AppMessages['register.booths.partners.address.placeholder'],
                })}
                required
              />
            </div>
            <div className={formGridStyles.col50}>
              <Controller
                control={control}
                name="booths.employees"
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    field={field}
                    options={numberOfEmployeesOptions}
                    id="booths.employees"
                    label={formatMessage({
                      id: AppMessages['register.booths.partners.numberOfEmployees.label'],
                    })}
                    error={error}
                    required
                  />
                )}
              />
            </div>
          </div>
          <div className={formGridStyles.row}>
            <div className={formGridStyles.col50}>
              <Controller
                control={control}
                name="booths.boothsCount"
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    field={field}
                    options={numberOfBoothsOptions}
                    id="booths.boothsCount"
                    label={formatMessage({
                      id: AppMessages['register.booths.partners.boothsCount.label'],
                    })}
                    error={error}
                    required
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
};
