import React, { ReactNode, useEffect } from 'react';
import { useScrollLock } from '@mantine/hooks';
import { motion, AnimatePresence } from 'framer-motion';

import { WithPortal } from 'hoc/with-portal';

import styles from './confirm-dialog.module.scss';

export const ConfirmDialog = ({ children, isOpen }: { children: ReactNode; isOpen: boolean }) => {
  const [, setScrollLocked] = useScrollLock();

  useEffect(() => {
    setScrollLocked(isOpen);
  }, [isOpen, setScrollLocked]);

  return (
    <WithPortal id="modal">
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="confirm-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.confirmDialogContainer}
          >
            <motion.div
              key="confirm"
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.3, opacity: 0 }}
              className={styles.confirmDialog}
            >
              {children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </WithPortal>
  );
};
