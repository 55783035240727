import { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

type WithPortalProps = { children: ReactNode; id: string };

export const WithPortal = ({ children, id }: WithPortalProps) => {
  const container = useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!container) return;

    container.current = document.getElementById(id);

    setMounted(true);
  }, [id]);

  return mounted && container.current !== null ? ReactDOM.createPortal(children, container.current) : null;
};
