import * as yup from 'yup';

import { AppMessages } from 'i18n/messages';

export const generateSchema = (formatMessage: any) =>
  yup.object().shape({
    email: yup
      .string()
      .email(
        formatMessage({
          id: AppMessages['validation.email'],
        }),
      )
      .required(
        formatMessage({
          id: AppMessages['validation.required'],
        }),
      ),
    businessName: yup.string().required(
      formatMessage({
        id: AppMessages['validation.required'],
      }),
    ),
    fullname: yup.string().required(
      formatMessage({
        id: AppMessages['validation.required'],
      }),
    ),
    mobileNumber: yup
      .string()
      .matches(
        /\+966\s([0-9]{3})\s([0-9]{3})\s([0-9]{3})/,
        formatMessage({
          id: AppMessages['validation.phone'],
        }),
      )
      .required(
        formatMessage({
          id: AppMessages['validation.required'],
        }),
      ),
    booths: yup.object().shape({
      region: yup
        .object()
        .nullable()
        .required(
          formatMessage({
            id: AppMessages['validation.required'],
          }),
        ),
      city: yup
        .object()
        .nullable()
        .required(
          formatMessage({
            id: AppMessages['validation.required'],
          }),
        ),
      address: yup.string().required(
        formatMessage({
          id: AppMessages['validation.required'],
        }),
      ),
      employees: yup
        .object()
        .nullable()
        .shape({
          value: yup.string().required(),
          label: yup.string(),
        })
        .required(
          formatMessage({
            id: AppMessages['validation.required'],
          }),
        ),
      boothsCount: yup
        .object()
        .nullable()
        .shape({
          value: yup.string().required(),
          label: yup.string(),
        })
        .required(
          formatMessage({
            id: AppMessages['validation.required'],
          }),
        ),
    }),
  });
