import clsx from 'clsx';
import ReactSelect from 'react-select';
import { Controller, FieldErrors, FieldError, FieldPath } from 'react-hook-form';
import React from 'react';

import selectStyles from '../select/select.module.scss';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { FieldContainer } from '../field/field';

import inputStyles from './date-picker.module.scss';
import { DatePickerProps } from './date-picker.types';
import { generateMonthList } from './date-picker.utils';

export const DatePicker = <InputsTypes, NameType extends FieldPath<InputsTypes> = FieldPath<InputsTypes>>({
  control,
  errors,
  label,
  required,
  monthName,
  dayName,
  yearName,
}: DatePickerProps<InputsTypes, NameType>) => {
  const { formatMessage } = useLocale();

  const getErrors = <ErrorTypes extends FieldErrors>(errors: ErrorTypes): FieldError | undefined => {
    if (errors.hasOwnProperty(dayName)) return errors[dayName];
    if (errors.hasOwnProperty(monthName)) return errors[monthName];
    if (errors.hasOwnProperty(yearName)) return errors[yearName];

    return undefined;
  };

  return (
    <FieldContainer id={yearName} label={label} required={required} errorMsg={getErrors(errors)?.message}>
      <fieldset className={inputStyles.fieldsContainer}>
        <Controller
          control={control}
          name={yearName}
          render={({ field, fieldState: { error } }) => (
            <div
              className={clsx('field', inputStyles.singleField, inputStyles.year, {
                [inputStyles.fieldWithError]: !!error,
              })}
            >
              <input
                {...field}
                id={yearName}
                value={field.value as string}
                className={inputStyles.input}
                placeholder={formatMessage({
                  id: AppMessages['datepicker.year'],
                })}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name={dayName}
          render={({ field, fieldState: { error } }) => (
            <div
              className={clsx('field', inputStyles.singleField, inputStyles.day, {
                [inputStyles.fieldWithError]: !!error,
              })}
            >
              <input
                {...field}
                id={dayName}
                value={field.value as string}
                className={inputStyles.input}
                placeholder={formatMessage({
                  id: AppMessages['datepicker.day'],
                })}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name={monthName}
          render={({ field, fieldState: { error } }) => (
            <ReactSelect<{ value: string; label: string }>
              options={generateMonthList(formatMessage)}
              {...field}
              value={field.value as { value: string; label: string }}
              instanceId={monthName}
              defaultValue={null}
              classNamePrefix="select-field"
              className={clsx(selectStyles.select, inputStyles.singleField, inputStyles.month, {
                [selectStyles.withError]: !!error,
              })}
              placeholder={formatMessage({
                id: AppMessages['datepicker.month'],
              })}
            />
          )}
        />
      </fieldset>
    </FieldContainer>
  );
};
