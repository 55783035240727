import clsx from 'clsx';
import React from 'react';
import NumberFormat from 'react-number-format';

import { FieldContainer } from '../field/field';

import inputStyles from './id-field.module.scss';
import { IdFieldProps } from './id-field.types';

export const IdField = <InputTypes,>({
  id,
  label,
  required,
  error,
  placeholder,
  field,
  value,
}: IdFieldProps<InputTypes>) => {
  return (
    <FieldContainer id={id} label={label} required={required} errorMsg={error?.message}>
      <div className={clsx('field', inputStyles.containerDir)}>
        <NumberFormat
          {...field}
          id={id}
          type="text"
          value={value}
          className={inputStyles.input}
          placeholder={placeholder}
          format="##########"
          displayType="input"
        />
      </div>
    </FieldContainer>
  );
};
