import clsx from 'clsx';
import { useFormContext, Controller } from 'react-hook-form';
import { useRef } from 'react';

import { Button } from 'components/shared/button/button';
import { DatePicker } from 'components/shared/form/date-picker/date-picker';
import { IdField } from 'components/shared/form/id-field/id-field';
import { Info } from 'components/shared/info/info';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import styles from '../hbb-form.module.scss';
import formGridStyles from '../../form-grid.module.scss';
import { HBBInputsTypes } from '../hbb-form.types';
import { Field } from 'components/shared/form/text-field/text-field';

export const SecondStep = () => {
  const { formatMessage } = useLocale();
  const {
    formState: { errors },
    register,
    control,
    watch,
    getValues,
  } = useFormContext<HBBInputsTypes>();

  const { ref: HCRef, ...HCRest } = register('hcFile');
  const HCFilePickerRef = useRef<HTMLInputElement | null>(null);

  const HCPickFileHandler = () => {
    if (HCFilePickerRef && HCFilePickerRef.current) {
      HCFilePickerRef.current.click();
    }
  };

  return (
    <>
      <div className={styles.field}>
        <span className={styles.fieldLabel}>
          {formatMessage({
            id: AppMessages['register.booths.hbb.healthCertificate.options.label'],
          })}
        </span>
        <div className={styles.fieldOptions}>
          <div className={styles.fieldOption}>
            <input type="radio" id="hc-positive" value={1} {...register('hc')} />
            <label htmlFor="hc-positive">
              {formatMessage({
                id: AppMessages['register.booths.hbb.healthCertificate.options.yes'],
              })}
            </label>
          </div>
          <div className={styles.fieldOption}>
            <input type="radio" id="hc-negative" value={0} {...register('hc')} />
            <label htmlFor="hc-negative">
              {formatMessage({
                id: AppMessages['register.booths.hbb.healthCertificate.options.no'],
              })}
            </label>
          </div>
        </div>
        {errors.hc && <div className={styles.fieldError}>{errors.hc.message}</div>}
      </div>
      <Info
        variant="info"
        title={formatMessage({
          id: AppMessages['register.booths.hbb.healthCertificate.info.heading'],
        })}
        content={formatMessage({
          id: AppMessages['register.booths.hbb.healthCertificate.info.content'],
        })}
      />

      {watch('hc') === '1' ? (
        <div>
          <div className={clsx(formGridStyles.row, styles.hcDataContainer)}>
            <div className={formGridStyles.col50}>
              <Field
                id="healthCertificateNumber"
                label={formatMessage({
                  id: AppMessages['register.booths.hbb.healthCertificate.number.label'],
                })}
                error={errors.healthCertificateNumber}
                required
                register={register('healthCertificateNumber')}
                placeholder="XXXXXXXXXX"
              />
            </div>
            <div className={formGridStyles.col50}>
              <DatePicker
                control={control}
                monthName="hcMonth"
                dayName="hcDay"
                yearName="hcYear"
                errors={errors}
                label={formatMessage({
                  id: AppMessages['register.booths.hbb.healthCertificate.expirationDate.label'],
                })}
                required
              />
            </div>
          </div>
          <div className={styles.hcDataContainer}>
            <span className={styles.fieldLabel}>
              {formatMessage({
                id: AppMessages['register.booths.hbb.healthCertificate.upload.label'],
              })}
            </span>
            <input
              type="file"
              {...HCRest}
              accept="image/*"
              ref={(e) => {
                HCRef(e);
                HCFilePickerRef.current = e;
              }}
              style={{ display: 'none' }}
              multiple
            />

            <Button
              size="big"
              variant="outlined"
              className={styles.uploadButton}
              onClick={HCPickFileHandler}
              type="button"
            >
              {formatMessage({
                id: AppMessages['form.upload'],
              })}
            </Button>
            <ul className={styles.fileList}>
              {watch('hcFile') !== undefined &&
                Array.from(getValues('hcFile') || '').map((file, index) => (
                  <li className={styles.file} key={index}>
                    {file.name}
                  </li>
                ))}
            </ul>
            {errors.hcFile && <div className={styles.fieldError}>{errors.hcFile.message}</div>}
          </div>
        </div>
      ) : (
        <Info
          variant="warning"
          title={formatMessage({
            id: AppMessages['register.booths.hbb.healthCertificate.warning'],
          })}
        />
      )}
    </>
  );
};
