import type { PrimitiveType, FormatXMLElementFn, Options } from 'intl-messageformat';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useTranslations = () => {
  const { formatMessage } = useLocale();

  const translate = (
    message: keyof typeof AppMessages,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: Options,
  ) => formatMessage({ id: AppMessages[message] }, values, opts);

  return { translate };
};
