import { useWindowScroll } from '@mantine/hooks';

export const useScrollToPageSection = () => {
  const [, scrollTo] = useWindowScroll();

  const scrollToRegister = () => {
    const sectionOffsetTop = document.getElementById('register_as')?.offsetTop;

    scrollTo({ y: sectionOffsetTop });
  };

  const scrollToFAQ = () => {
    setTimeout(() => {
      const sectionOffsetTop = document.getElementById('faq_heading')?.offsetTop;

      scrollTo({ y: sectionOffsetTop });
    }, 300);
  };

  const scrollToContactForm = () => {
    const sectionOffsetTop = document.getElementById('contact_form')?.offsetTop;

    console.log(sectionOffsetTop, document.getElementById('contact_form'));

    scrollTo({ y: sectionOffsetTop });
  };

  return { scrollToRegister, scrollToFAQ, scrollToContactForm };
};
