import { useContext } from 'react';

import { ModalContext } from 'context/modal/ ModalContextController';
import { ModalContextType } from 'context/modal/ModalContext.types';

export const useModal: () => ModalContextType = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModal must be used within an ModalContextController');
  }

  return context;
};
